import { Link } from "react-router-dom";
import React, { useState, useCallback, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
  FormControl,
} from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useDropzone } from "react-dropzone";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { fetchDeliveryAddressesListStart, saveDeliveryAddressesStart } from "../store/actions/DeliveryAction";
import { useTranslation, withTranslation } from "react-multi-lang";
import { apiConstants } from "../Constant/constants";
import { GoogleApiWrapper } from "google-maps-react";
import * as Yup from "yup";
import AddressSubmitForm from "../Profile/AddressSubmitForm";
import NewAddressForm from "../Profile/NewAddressForm";

const AddNewAddressPopUpModal = (props) => {
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (
      !skipRender &&
      !props.deliveryAddressesSave.loading &&
      Object.keys(props.deliveryAddressesSave.data).length > 0
    ) {
      props.dispatch(fetchDeliveryAddressesListStart());
      props.closeWriteReviewModal();
    }
    setSkipRender(false);
  }, [props.deliveryAddressesSave]);



  return (
    <>
      <Modal
        className="modal-dialog-center write-review-modal"
        size="lg"
        centered
        show={props.writeReview}
        onHide={props.closeWriteReviewModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("add_delivery_address")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <AddressSubmitForm editAddress={{}} isModal={true} /> */}
          <NewAddressForm isModal={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  deliveryAddressesSave: state.delivery.deliveryAddressesSave,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const connector = connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(AddNewAddressPopUpModal));

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connector);
